<template>
  <b-card no-body class="set-scroll">
    <div class="child-scroll">
        <div v-if="chats.length > 0">
            <Bubble :side="user.id == chat.from_user_id ? 'right' : 'left'" :text="chat.text" :hour="chat.created_at" :file="chat.file" v-for="chat in chats" :key="chat.id"/>
        </div>
        <Empty text="Select a contact to chat" v-else/>
    </div>
    <div class="mt-3">
        <ChatInput :id="selected" />
    </div>
  </b-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Bubble from './BubbleMessage.vue'
import ChatInput from './ChatInput'
export default {
    props: ['selected'],
    components: { Bubble, ChatInput },
    data () {
        return {
            loading: false
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        ...mapActions('chat', ['getChat']),
        loadData () {
            this.loading = true
            console.log("DATA LOG", this.selected)
            this.getChat(this.selected).then(response => {
                console.log("RESPONSE", response)
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('chat', ['chats']),
        ...mapState('auth', ['user'])
    }
}
</script>
<style scoped>
.set-scroll{
max-height:700px;
    padding: 0px !important;
    padding-top: 2.5rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.child-scroll{
    min-height: 400px;
    overflow-y: scroll
}
.child-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.child-scroll::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
    border-radius: 20px;
}

.child-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #5361fdc5;
}

</style>
